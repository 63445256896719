import React, { useEffect, useCallback, useMemo, memo } from 'react';
import clsx from 'clsx';
import loadable from '@loadable/component';

// Imports => Constants
import { ICONS } from '@constants';

// Imports => Utilities
import { AcIsSet, AcGetPagination } from '@utils';

// Imports => Hooks
import { usePagination } from '../../hooks';

// Imports => Atoms
const AcIcon = loadable(() => import('@atoms/ac-icon/ac-icon.web'));

const _CLASSES = {
  PAGINATION: {
    MAIN: 'ac-table__pagination',
    TOTAL: 'ac-table__pagination__total',
    CONTROLS: {
      MAIN: 'ac-table__pagination__controls',
      ACTION: 'ac-table__pagination__action',
      PREVIOUS: 'ac-table__pagination__action--previous',
      NEXT: 'ac-table__pagination__action--next',
      ICON: 'ac-table__pagination__icon',
    },
    PAGES: {
      NUMBERS: 'ac-table__pagination__numbers',
      NUMBER: 'ac-table__pagination__number',
      CURRENT: 'ac-table__pagination__number--current',
      SEPARATOR: 'ac-table__pagination__number--separator',
    },
  },
};

const AcPagination = ({
  total,
  current_page,
  per_page,
  from,
  to,
  last_page,
  callback,
}) => {
  const handleCallback = useCallback(
    (num) => {
      if (callback) callback(num);
    },
    [callback]
  );

  const handleNext = useCallback(() => {
    if (current_page + 1 > last_page) return;
    handleCallback(current_page + 1);
  }, [handleCallback, current_page]);

  const handlePrevious = useCallback(() => {
    if (current_page - 1 < 0) return;
    handleCallback(current_page - 1);
  }, [handleCallback, current_page]);

  const handleKeyUp = (event) => {
    if (!AcIsSet(event)) return;
    if (event && event.persist) event.persist();
    const key = event.key || event.which;

    const $active_element = document.activeElement;

    const $inputs = ['input', 'select', 'button', 'textarea', 'form'];

    if (key) {
      if (
        $active_element &&
        $inputs.indexOf($active_element.tagName.toLowerCase()) === -1
      ) {
        switch (key) {
          case 'ArrowLeft':
          case 37:
            handlePrevious();
            break;

          case 'ArrowRight':
          case 39:
            handleNext();
            break;

          default:
        }
      }
    }
  };

  const addEvents = () => {
    document.addEventListener('keyup', handleKeyUp, { passive: true });
  };

  const removeEvents = () => {
    document.removeEventListener('keyup', handleKeyUp, { passive: true });
  };

  useEffect(() => {
    addEvents();

    return () => {
      removeEvents();
    };
  }, []);

  const renderPageNumbers = useMemo(() => {
    if (!AcIsSet(last_page)) return null;

    const pagination = AcGetPagination(current_page, {
      range: 3,
      pages: last_page,
    });

    let result = [];

    const has_first_page = pagination.find((item) => item.num === 1);
    const has_last_page = pagination.find((item) => item.num === last_page);

    if (!has_first_page) {
      result.push(
        <span
          className={`ac-table__pagination__number 
              ${current_page === 1 ? _CLASSES.PAGINATION.PAGES.CURRENT : ''}`}
          key={'first-page'}
          onClick={() => handleCallback(1)}
        >
          {1}
        </span>
      );

      if (pagination[0]?.num - 1 > 1) {
        result.push(
          <span
            key={`ac-table__pagination__separator-first`}
            className={`ac-table__pagination__number ac-table__pagination__number--separator`}
          >
            ...
          </span>
        );
      }
    }

    let n = 0;
    const len = pagination?.length;

    for (n; n < len; n++) {
      const page = pagination[n];
      result.push(
        <span
          className={`ac-table__pagination__number 
              ${
                current_page === page.num
                  ? _CLASSES.PAGINATION.PAGES.CURRENT
                  : ''
              }`}
          key={`ac-table__pagination__separator-${page.num}`}
          onClick={() => handleCallback(page.num)}
        >
          {page.num}
        </span>
      );
    }

    if (!has_last_page) {
      if (pagination[pagination?.length - 1]?.num + 1 < last_page) {
        result.push(
          <span
            key={`ac-table__pagination__separator-last`}
            className={`ac-table__pagination__number ac-table__pagination__number--separator`}
          >
            ...
          </span>
        );
      }
      result.push(
        <span
          className={`ac-table__pagination__number 
              ${
                current_page === last_page
                  ? _CLASSES.PAGINATION.PAGES.CURRENT
                  : ''
              }`}
          key={'last-page'}
          onClick={() => handleCallback(last_page)}
        >
          {last_page}
        </span>
      );
    }

    return result;
  }, [current_page, last_page, per_page]);

  return (
    <div className={_CLASSES.PAGINATION.MAIN}>
      <span className={_CLASSES.PAGINATION.TOTAL}>
        <span>
          <strong>{from}</strong> - <strong>{to}</strong> out of{' '}
          <strong>{total}</strong> results
        </span>
      </span>

      {last_page > 1 && (
        <div
          className={_CLASSES.PAGINATION.CONTROLS.MAIN}
          style={{ marginRight: '10px' }}
        >
          <div
            className={`${_CLASSES.PAGINATION.CONTROLS.ACTION} ${_CLASSES.PAGINATION.CONTROLS.PREVIOUS}`}
            disabled={current_page === 1}
            onClick={handlePrevious}
          >
            <AcIcon
              icon={ICONS.ARROW_LEFT}
              className={_CLASSES.PAGINATION.CONTROLS.ICON}
            />
          </div>
          <div className={'ac-table__pagination__numbers'}>
            {renderPageNumbers}
          </div>
          <div
            className={`${_CLASSES.PAGINATION.CONTROLS.ACTION} ${_CLASSES.PAGINATION.CONTROLS.NEXT}`}
            disabled={current_page === last_page}
            onClick={handleNext}
          >
            <AcIcon
              icon={ICONS.ARROW_RIGHT}
              className={_CLASSES.PAGINATION.CONTROLS.ICON}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(AcPagination);
