import React, { useEffect, useMemo, memo } from 'react';
import loadable from '@loadable/component';
import clsx from 'clsx';

// Imports => Constants
import { TYPES } from '@constants';

// Imports => Atoms
const AcSelectBox = loadable(() =>
	import('@atoms/ac-select-box/ac-select-box.web')
);

const _CLASSES = {
	MAIN: 'ac-select-box--filter',
};

const AcFilterSelectBox = ({
	id,
	label,
	name,
	value,
	options = [],
	disabled = false,
	multiselect = false,
	callback,
}) => {
	const handleCallback = (event, name, value) => {
		if (event && event.persist) event.persist();
		if (event && event.preventDefault) event.preventDefault();
		if (event && event.stopPropagation) event.stopPropagation();

		if (disabled) return;
		if (callback) callback(event, name, value);
	};

	const getFilterSelectBoxOptions = useMemo(() => {
		return {
			type: TYPES.TEXT,
			label,
			name,
			placeholder: null,
			value,
			callback: handleCallback,
			validation: false,
			maxOptions: 6,
			disabled: !options?.length,
			multiple: multiselect,
			options,
		};
	}, [label, name, value, disabled, handleCallback, options]);

	return (
		<AcSelectBox
			{...getFilterSelectBoxOptions}
			is_filter
			title={`Filter results by: ${label}`}
		/>
	);
};

export default memo(AcFilterSelectBox);
