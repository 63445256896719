// Imports => React
import React, {
	useEffect,
	useState,
	useMemo,
	forwardRef,
	useImperativeHandle,
} from 'react';
import ReactDOMServer from 'react-dom/server';
import clsx from 'clsx';
import GoogleMapReact from 'google-map-react';

// Imports => Config
import config from '@config';

// Imports => Constants
import {
	ICONS,
	KEYS,
	MAP_CLUSTER_OPTIONS,
	MAP_OPTIONS,
	PERMISSIONS,
	SIZES,
	THEMES,
	VISUALS,
} from '@constants';

// Imports => Utilties
import {
	AcIsSet,
	AcIsNull,
	AcGetMapMarkerImage,
	AcGenerateMapMarkerElement,
} from '@utils';

// Imports => Molecules
import AcCheckPermissions from '@molecules/ac-check-permissions/ac-check-permissions.web';

// Imports => Atoms
import AcRipple from '@atoms/ac-ripple/ac-ripple.web';
import AcIcon from '@atoms/ac-icon/ac-icon.web';

const _CLASSES = {
	MAIN: 'ac-project-map-widget',
};

let _boundDelay = null;

const AcProjectMapWidget = ({ location, equipment, callback }) => {
	const [mapInstance, setMapInstance] = useState(null);
	const [marker, setMarker] = useState(null);
	const [lines, setLines] = useState([]);

	useEffect(() => {
		if (AcIsSet(mapInstance) && AcIsSet(location)) {
			const { map, maps } = mapInstance;
			if (AcIsSet(marker) && marker.setPosition) {
				const { lat, lng } = location;
				const loc = new maps.LatLng(lat, lng);
				marker.setPosition(loc);
				map.panTo(loc);
				handleUpdateLines(loc, equipment, { map, maps });
			}
		}
	}, [location, equipment]);

	const handleCallback = (event) => {
		if (event && event.preventDefault) event.preventDefault();
		if (event && event.stopPropagation) event.stopPropagation();

		if (callback) callback();
	};

	const handleUpdateLines = (from, locations = [], { map, maps }) => {
		return new Promise((resolve) => {
			if (AcIsSet(lines) && lines.length > 0) {
				const collection = lines || [];
				const len = collection.length;
				let n = 0;

				for (n; n < len; n++) {
					const poly = collection[n];
					if (poly && poly.setMap) poly.setMap(null);
				}
			}

			const llen = locations.length;
			let b = 0;
			let result = [];

			for (b; b < llen; b++) {
				const { location } = locations[b];
				if (!AcIsSet(location)) continue;

				const line = renderLineSymbol(from, location, map, maps);
				result.push(line);
			}

			setLines(result);
			resolve(result);
		});
	};

	const renderProjectMarker = (location, map, maps) => {
		const url = AcGetMapMarkerImage(KEYS.PROJECTS);

		// Marker image
		// original size: 268 x  352

		const iconElement = AcGenerateMapMarkerElement(url, 32, 42, -16, -40.5);

		const position = new maps.LatLng(location.lat, location.lng);

		const m = new maps.marker.AdvancedMarkerElement({
			position,
			map,
			content: iconElement,
		});

		setMarker(m);
	};

	const renderEquipmentMarker = (item, map, maps) => {
		const { entity, location: position } = item;
		const url = AcGetMapMarkerImage(entity, KEYS.EQUIPMENT);

		// Marker image
		// original size: 268 x  352

		const iconElement = AcGenerateMapMarkerElement(url, 28, 28, -14, -14);

		const marker = new maps.marker.AdvancedMarkerElement({
			position,
			map,
			content: iconElement,
		});

		return marker;
	};

	const renderLineSymbol = (from, to, map, maps) => {
		const icon = {
			path: 'M 0,-1 0,1',
			strokeOpacity: 1,
			strokeColor: '#1a2f35',
			scale: 1.5,
		};

		const l = new maps.Polyline({
			path: [from, to],
			geodesic: true,
			strokeOpacity: 0,
			icons: [
				{
					icon,
					offset: '0',
					repeat: '7px',
				},
			],
			map,
		});

		return l;
	};

	const init = async ({ map, maps }) => {
		if (_boundDelay) clearTimeout(_boundDelay);
		if (!AcIsSet(location)) return;

		renderProjectMarker(location, map, maps);

		const center = new maps.LatLng(location.lat, location.lng);
		const collection = equipment || [];
		const len = collection.length;
		let n = 0;

		let bounds = new maps.LatLngBounds();
		bounds.extend(center);

		for (n; n < len; n++) {
			const item = collection[n];

			const { entity, location: position } = item;

			if (!AcIsSet(entity)) continue;
			if (AcIsNull(position)) continue;

			const marker = renderEquipmentMarker(item, map, maps);

			const b = new maps.LatLng(position.lat, position.lng);
			bounds.extend(b);
		}

		await handleUpdateLines(center, equipment, { map, maps });

		maps.event.addListenerOnce(map, 'tilesloaded', () => {
			if (len > 0) {
				map.fitBounds(bounds);

				maps.event.addListenerOnce(map, 'idle', () => {
					const zoomLevel = map.getZoom();
					if (zoomLevel < 3) {
						map.setZoom(3);
					} else if (zoomLevel > 13) {
						map.setZoom(13);
					}
				});
			} else {
				map.setCenter(center);
			}
		});

		setMapInstance({ maps, map });
	};

	const renderMapWidget = useMemo(() => {
		if (!location) return null;

		const { maps_key: key } = config;
		return (
			<GoogleMapReact
				{...MAP_OPTIONS}
				bootstrapURLKeys={{
					key,
					libraries: ['places', 'marker'],
					language: 'en',
					region: 'en',
				}}
				onGoogleApiLoaded={init}
				key={location}
			/>
		);
	}, [init, location, equipment]);

	const getMainClassNames = useMemo(() => {
		return clsx(_CLASSES.MAIN);
	}, []);

	return (
		<div className={getMainClassNames}>
			{renderMapWidget}
			<AcCheckPermissions allowed={PERMISSIONS.PROJECT.UPDATE}>
				<div
					className={'ac-project-map-widget-button'}
					onClick={handleCallback}
				>
					<AcIcon icon={ICONS.MAP_MARKER_EDIT} />
					<span>Change project location</span>
					<AcRipple theme={THEMES.PITCH} size={SIZES.SMALL} simple />
				</div>
			</AcCheckPermissions>
		</div>
	);
};

export default AcProjectMapWidget;
